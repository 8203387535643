




































































import { Component, Vue } from 'vue-property-decorator';

import { dispatchGetYtTasks, dispatchUpdateYtTask } from '@/store/yt-tasks/actions';
import { readOneYtTask } from '@/store/yt-tasks/getters';
import { IYtTaskUpdate } from '@/interfaces/yt-tasks';
import { nameRules } from './validators';

@Component
export default class EditTask extends Vue {
  public valid = false;
  public name = '';
  public description = '';
  public deep = 30;
  public nameRules = nameRules;

  public async mounted() {
    await dispatchGetYtTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
      this.deep = this.task.deep;
    } else this.name = this.description = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedTask: IYtTaskUpdate = {
        name: this.name,
        deep: this.deep,
        description: this.description || undefined,
      };
      await dispatchUpdateYtTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/youtube-tasks');
    }
  }

  get task() {
    return readOneYtTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public isNew() {
    return this.task?.status === 'NEW';
  }

}
